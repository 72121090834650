import React from "react";
import {Link} from "react-router-dom";
import ContactEntry from "./ContactEntry";
import {location_logo, mail_logo, simm_logo, tel_logo} from "../resources/imports/FooterImages"
import {addressLink, mailLink, telLink} from "../resources/data/contactData";
import "../resources/css/footer.css"

const Footer = () => {
    return (
        <footer>
            <Link to={"/"}>
                <img src={simm_logo} alt={"simm logo"} draggable="false"/>
            </Link>
            <div>
                <ContactEntry
                    logo={location_logo}
                    alt={"localisation"}
                    value={"ZI NORD 47200 MARMANDE"}
                    href={addressLink}/>
                <ContactEntry
                    logo={tel_logo}
                    alt={"numéro de téléphone"}
                    value={"05 53 64 21 71"}
                    href={telLink}/>
                <ContactEntry
                    logo={mail_logo}
                    alt={"email"}
                    value={"simm@wanadoo.fr"}
                    href={mailLink}/>
            </div>
        </footer>
    );
};

export default Footer;