import case_study from "../icons/case_study.svg";
import maintenance from "../icons/maintenance.svg";
import distribution from "../icons/distribution.svg";

const list = [
    {
        logo: case_study,
        title: "Conception / Fabrication",
        value: "Vous avez des projets, notre bureau d'étude saura trouver les solutions qui vous conviennent",
        key: "case_study"
    },
    {
        logo: maintenance,
        title: "Maintenance",
        value: "Avec nos 8 techniciens itinérants et 4 véhicules techniques, nous intervenons dans les meilleurs " +
            "délais à travers toute la nouvelle aquitaine",
        key: "maintenance"
    },
    {
        logo: distribution,
        title: "Distribution",
        value: "Simm propose une gamme de produits capables de solutionner vos problèmes liées au levage et à la " +
            "manutention",
        key: "distribution"
    }
];

export default list;