import React from "react";
import presentation from "../resources/images/presentation.jpg"
import Separator from "../components/Separator";
import "../resources/css/std_presentation.css"
import list from "../resources/data/presentationTexts";

const Presentation = () => {
    return (
        <div id={"presentation"}>
            <h1>
                s.i.m.m intervient sur vos equipements de levage
            </h1>
            <Separator/>
            <p>
                Implantée à Marmande, dans le coeur du Lot-et-Garonne, la société S.I.M.M est une agence
                ABUS Levage et un point service JAY ELECTRONIQUE dédié a la vente, ainsi qu'à la réparation
                des radiocommandes
            </p>
            <p>
                Les quatorze personnes de la société S.I.M.M assurent depuis plus de trente ans la distribution,
                la maintenance, et la mise en conformité de tous vos équipements de levages
            </p>
            <ul>{list.map(e => <li key={e.key}>{e.text}</li>)}</ul>
            <p>
                Notre bureau d'études et nos 8 techniciens itinérants sont a votre disposition, afin de vous offrir
                professionnalisme, réactivité et qualité.
            </p>
            <img src={presentation} alt={""} draggable={"false"}/>
        </div>
    );
};

export default Presentation;
