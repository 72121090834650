import React from "react";
import "../resources/css/products.css"
import ProductPage from "../components/ProductPage";
import products from "../resources/data/productsData";

const Products = (props) => {
    const {match: {params}} = props;
    const product = params["product_name"]

    return (
        <ProductPage
            product_name={products[product].name}
            product_desc={products[product].text}
            product_pics={products[product].pics}/>
    );
}

export default Products;