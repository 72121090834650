const navigationLinks = [
    {link: "/products/potences", text: "Potences", key: "potences"},
    {link: "/products/systemes-manutention-aeriens", text: "Systèmes de manutentions aériens", key: "syst_aeriens"},
    {link: "/products/portiques-legers", text: "Portiques Légers", key: "portiques"},
    {link: "/products/palans-electriques", text: "Palans electriques", key: "palans_electriques"},
    {link: "/products/treuils-electriques", text: "Treuils Electriques", key: "treuils_electriques"},
    {link: "/products/palonniers", text: "Palonniers", key: "palonniers"},
    {link: "/products/radio", text: "Radio-commandes", key: "radio"},
    {link: "/products/accessoires", text: "Accessoires", key: "accessoires"}
];

export default navigationLinks;