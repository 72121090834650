import React from "react";
import {Route, Switch} from "react-router-dom";
import Index from "../pages/Index";
import Presentation from "../pages/Presentation";
import Maintenance from "../pages/Maintenance"
import Contact from "../pages/Contact";
import Products from "../pages/Products";
import NotFound from "../pages/errors/404";


const Container = () => {
    return (
        <main id={"wrapper"}>
            <Switch>
                <Route path="/" exact={true} component={Index}/>
                <Route path="/presentation" exact={true} component={Presentation}/>
                <Route path="/maintenance" exact={true} component={Maintenance}/>
                <Route path="/contact" exact={true} component={Contact}/>
                <Route path="/products/:product_name" exact={true} component={Products}/>
                <Route component={NotFound}/>
            </Switch>
        </main>
    );
};

export default Container;
