import React from "react";

const ContactEntry = (props) => {
    return (
        <a href={props.href}>
            <img src={props.logo} alt={props.alt} draggable={false}/>
            <span>{props.value}</span>
        </a>
    )
};

export default ContactEntry;