import React from "react";
import Separator from "../components/Separator";
import "../resources/css/std_presentation.css"
import maintenance from "../resources/images/maintenance.jpg";
import list from "../resources/data/maintenanceData";

const Maintenance = () => {
    return (
        <div id={"maintenance"}>
            <h1>Maintenance</h1>
            <Separator/>
            <p>
                La qualité d'un produit s'apprécie aussi par le professionnalisme et la réactivité de son service
                après-vente. C'est pourquoi nous possédons un stock important d'organes mécaniques et de composants
                électriques de toutes marques. Nos techniciens expérimentés, tous en possession du CACES, mettent à
                votre disposition leur expertise pour les opérations suivantes:
            </p>
            <ul>{list.map(e => <li key={e.key}>{e.text}</li>)}</ul>
            <Separator/>
            <h1>Contrat de maintenance SIMM</h1>
            <p>
                Vos équipements doivent être contrôlés une fois par an au minimum. En nous adaptant à vos besoins et à
                votre agenda, nous vous proposons un contrat d'entretien annuel qui vous permettra de conserver vos
                équipements dans les meilleures conditions. A l'issue de cette prestation, un rapport d'invervention
                détaillant les opérations effectuées et les points d'attention à surveiller vous sera remis.
            </p>
            <img src={maintenance} alt={""} draggable={"false"}/>
        </div>
    )
};

export default Maintenance;