import React from "react";
import Slider from "react-slick";
import Separator from "./Separator";

const ProductPage = (props) => {
    const settings = {
        dots: false,
        lazyLoad: true,
        speed: 1000,
        autoplaySpeed: 2500,
        fade: true,
        autoplay: true,
        pauseOnHover: false,
    };

    return (
        <div id={"products"}>
            <h1>{props.product_name}</h1>
            <Separator/>
            <p>{props.product_desc}</p>
            <Slider {...settings}>
                {props.product_pics.map(p =>
                    <img
                        src={p}
                        alt={""}
                        key={p + ""}
                        draggable={false}/>)}
            </Slider>
        </div>
    );
}

export default ProductPage;