const list = [
    {text: "Relevés de côtes", key: "ribs"},
    {text: "Déménagements de vos équipements de levage", key: "lift_equipment_move"},
    {text: "Inspections et révisions", key: "inspections_and_revisions"},
    {
        text: "Réparation, mise aux normes et modifications de vos appareils de levage",
        key: "repair_upgrade_modification_lifting_equipement"
    },
    {text: "Réception technique", key: "technical_reception"}
];

export default list;