import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import Header from "./components/Header";
import NavigationBar from "./components/NavigationBar";
import Container from "./components/Container";
import Footer from "./components/Footer";
import "./resources/css/root.css";

const App = () => {
    return (
        <Router>
            <Header/>
            <NavigationBar/>
            <Container/>
            <Footer/>
        </Router>
    );
}

export default App;