import React from "react";

const Field = (props) => {
    return (
        <div className={"contact-field"}>
            <div>{props.name}</div>
            <a href={props.link}>{props.value}</a>
        </div>
    );
}

export default Field;