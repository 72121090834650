import React from "react";
import {Link} from "react-router-dom";
import "../resources/css/navigationbar.css"
import navigationLinks from "../resources/data/navigationLinks"

const NavigationBar = () => {
    return (
        <nav id={"navigation-buttons"}>
            <Link to="/presentation">Présentation</Link>
            <div>
                <span>Produits</span>
                <div>
                    {navigationLinks.map(e => {
                        return (<Link to={e.link} key={e.key}>{e.text}</Link>)
                    })}
                </div>
            </div>
            <Link to="/maintenance">Maintenance</Link>
            <Link to="/contact">Nous Contacter</Link>
        </nav>
    );
};

export default NavigationBar;