import {
    potence_1, potence_2, potence_3, potence_4,
    systeme_manutention_1,
    systeme_manutention_2,
    systeme_manutention_3,
    systeme_manutention_4,
    portique_1, portique_2, portique_3, portique_4,
    palan_1, palan_2, palan_3,
    treuil_1, treuil_2, treuil_3, treuil_4,
    palonnier_1, palonnier_2,
    radio_1, radio_2,
    fin_de_course, lis, enrouleur
} from "../imports/ProductsImages";


const products = {
    "potences": {
        name: "Potences",
        text: "Les potences se déclinent en plusieurs versions, elles peuvent être fixées au sol, contre un mur, ou " +
            "adaptées sur des supports standards existants. Elles sont motorisées ou manuelles, tournent sur 360° " +
            "ou 270° et peuvent être équipées avec des treuils ou des palans électriques. Enfin, les potences sont " +
            "commandées par une boite à boutons ou une radiocommande, leur capacité de levage peut atteindre 6.3 " +
            "tonnes et leur portée jusqu'à 10 mètres.",
        pics: [potence_1, potence_2, potence_3, potence_4]
    },
    "systemes-manutention-aeriens": {
        name: "Systèmes de manutention aériens",
        text: "Qu'elles soient monopoutres ou bipoutres, en acier ou en aluminium, ces solutions s'adaptent facilement " +
            "sur vos structures porteuses existantes. Votre organisation de travail change, pas de problème nos " +
            "systèmes sont entièrement modulables et capables de s'adapter au mieux a vos impératifs de production.",
        pics: [systeme_manutention_1, systeme_manutention_2, systeme_manutention_3, systeme_manutention_4]
    },
    "portiques-legers": {
        name: "Portiques Légers",
        text: "Quand les opérations de levage se déroulent sur des sites temporaires, ou sur plusieurs lieux " +
            "simultanés, la solution du portique d'atelier présente de nombreux avantages. Celui-ci est facilement " +
            "transportable, démontable et réutilisable. Il peut être manuel ou motorisé, offrant des capacités de " +
            "levage allant jusqu'à 6.3 tonnes et des largueur maximum de 10 mètres",
        pics: [portique_1, portique_2, portique_3, portique_4]
    },
    "palans-electriques": {
        name: "Palans électriques",
        text: "Nos palans, d'une capacité de 200kg à 4 tonnes, se caractérisent par un faible encombrement, allié à " +
            "une fiabilité à toute épreuve (garnitures de frein à longévité d'un million de cycles). Ils sont " +
            "équipés de deux vitesses de levage et peuvent être couplés à un grand nombre d'équipements de série.",
        pics: [palan_1, palan_2, palan_3]
    },
    "treuils-electriques": {
        name: "Treuils électriques",
        text: "Notre gamme de treuils électriques à faible encombrement, reconnus pour leur fiabilité et leurs " +
            "performances, est conçue pour garantir un fonctionnement irréprochable dans les conditions d'utilisation " +
            "les plus difficiles. Facilement adaptables, leurs moteurs à attaque directe permet un dosage progressif " +
            "de la force et de la vitesse. D'une capacité comprise entre 1 et 100 tonnes, nos chariots peuvent être " +
            "suspendus, surbaissés, monorail ou bi rails, avec simple ou double mécanisme de levage.",
        pics: [treuil_1, treuil_2, treuil_3, treuil_4]
    },
    "palonniers": {
        name: "Palonniers",
        text: "Depuis plus de 20 ans, notre département technique conçoit et produit des solutions de préhension " +
            "personnalisées: Exposez-nous votre problème et nous vous proposerons la solution",
        pics: [palonnier_1, palonnier_2]
    },
    "radio": {
        name: "Radio-commandes",
        text: "Nous proposons une gamme de radiocommandes sécurisées, destinées au pilotage de vos systèmes de " +
            "levage et de manutention. Standards ou spécifiques, ces émetteurs se caractérisent par leur robustesse " +
            "et leur confort d'utilisation unique. Commande synchronisée, fonction infra-rouge, création d'une zone " +
            "de travail dédiée, utilisation en multimode, ces accessoires sont capables de solutionner la plupart de " +
            "vos problématiques industrielles.",
        pics: [radio_1, radio_2]
    },
    "accessoires": {
        name: "Accessoires",
        text: "Nous vous proposons une gamme d'accessoires " +
            "(limiteurs de charge, afficheurs, élingues, moufles, etc...) qui compléteront efficacement vos " +
            "équipements de levage",
        pics: [fin_de_course, lis, enrouleur]
    }
};

export default products;