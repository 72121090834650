import index_1 from "../images/index/index_1.jpg";
import index_2 from "../images/index/index_2.jpg";
import index_3 from "../images/index/index_3.jpg";
import index_4 from "../images/index/index_4.jpg";
import index_5 from "../images/index/index_5.jpg";
import index_6 from "../images/index/index_6.jpg";
import index_7 from "../images/index/index_6.jpg";
import index_8 from "../images/index/index_7.jpg";

export const carouselImages = [
    index_1,
    index_2,
    index_3,
    index_4,
    index_5,
    index_6,
    index_7,
    index_8
];