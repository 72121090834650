import React from "react";
import {Link} from "react-router-dom";
import simm_logo from "../resources/icons/simm_logo.svg";
import "../resources/css/header.css"

const Header = () => {
    return (
        <header>
            <a id={"skip-link"} href={"#wrapper"}>Skip to main</a>
            <Link to={"/"}>
                <img src={simm_logo} alt="Simm Logo" draggable={false}/>
            </Link>
            <p>
                ponts-roulants, palans, potences, portiques, montage, maintenance, mise en conformité, radio-commandes,
                accessoires levage
            </p>
        </header>
    );
};

export default Header;