import React from "react";
import Slider from "react-slick"
import Separator from "../components/Separator";
import Activity from "../components/Activity";
import "../resources/css/index.css"
import {carouselImages} from "../resources/imports/IndexCarouselImages";
import list from "../resources/data/indexActivities";

const Index = () => {
    const settings = {
        dots: false,
        lazyLoad: true,
        speed: 1000,
        autoplaySpeed: 2500,
        fade: true,
        autoplay: true,
        pauseOnHover: false,
    };
    return (
        <div id={"index"}>
            <Slider {...settings}>
                {carouselImages.map((e, i) =>
                    <img src={e} alt={"slider"} draggable={false} key={`sliderimage${i}`}/>
                )}
            </Slider>
            <h1>vente - mise en conformité - maintenance de vos équipements de levage</h1>
            <Separator/>
            <h2>
                potences - systèmes de manutention aérienne - portiques - palans ou treuils électriques -
                radio commandes - palonniers - equiments spécifiques
            </h2>
            <div id="activities-container">
                {
                    list.map(e =>
                        <Activity
                            logo={e.logo}
                            title={e.title}
                            value={e.value}
                            key={e.key}/>
                    )
                }
            </div>
        </div>
    );
};

export default Index;