import React from "react";

const Activity = (props) => {
    return (
        <div>
            <img src={props.logo} alt={""} draggable={false}/>
            <h3>{props.title}</h3>
            <div>{props.value}</div>
        </div>
    )
}

export default Activity;