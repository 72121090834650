import React from "react";
import "../resources/css/contact.css"
import {addressLink, mailLink, mapsIntegration, telLink} from "../resources/data/contactData";
import Field from "../components/Field";

const Contact = () => {
    return (
        <div id={"contact"}>
            <iframe
                src={mapsIntegration}
                id={"map"}
                frameBorder={0}
                aria-hidden={false}
                tabIndex={0}
                title={"maps_iframe"}/>
            <div id={"values"}>
                <h1>Coordonées</h1>
                <Field
                    name={"Email: "}
                    value={"simm@wanadoo.fr"}
                    link={mailLink}/>
                <Field
                    name={"Tel: "}
                    value={"05 53 64 21 71"}
                    link={telLink}/>
                <Field
                    name={"Adresse: "}
                    value={"ZI NORD 47200 MARMANDE"}
                    link={addressLink}/>
            </div>
        </div>
    );
};

export default Contact;